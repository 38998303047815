import i18n from '@/i18n'
import Store from '@/store'
import pdfMake from 'pdfmake/build/pdfmake'
import pdfFontsBn from 'pdfmake/build/vfs_fonts_bn'
import pdfFontsEn from 'pdfmake/build/vfs_fonts_en'
import { dateFormat, mobileNumber } from '@/Utils/fliter'

const exportPdfDetails = async (baseUrl, uri, orgId, reportTitle, proposal, vm) => {
    try {
        Store.commit('mutateCommonProperties', {
          loading: true
        })
        if (i18n.locale === 'bn') {
          pdfMake.vfs = pdfFontsBn.pdfMake.vfs
        } else {
            pdfMake.vfs = pdfFontsEn.pdfMake.vfs
        }
        let pdfContent = []
        pdfContent = [
            { text: reportTitle, style: 'hh', alignment: 'center' }
        ]

        pdfContent.push({ text: '', style: 'fertilizer' })
        const topRows = [
          [
            { text: vm.$t('certify_form.gen_info'), bold: true, style: 'th', colSpan: '4', fillColor: '#dee2e6', alignment: 'center' },
            { },
            { },
            { }
          ],
          [
            { text: vm.$t('external_research.research_type'), bold: true, style: 'td' },
            { text: vm.$i18n.locale === 'en' ? proposal.researchTypeName : proposal.researchTypeName_bn, style: 'td' },
            { text: vm.$t('research_manage.thematic_area'), bold: true, style: 'td' },
            { text: vm.$i18n.locale === 'en' ? proposal.thematic_area_name : proposal.thematic_area_name_bn, style: 'td' }
          ],
          [
            { text: vm.$t('research_manage.project_title'), bold: true, style: 'td' },
            { text: vm.$i18n.locale === 'en' ? proposal.project_title : proposal.project_title_bn, style: 'td', colSpan: '3' },
            { },
            { }
          ],
          [
            { text: vm.$t('external_research.project_duation_from_date'), bold: true, style: 'td' },
            { text: dateFormat(proposal.from_date), style: 'td' },
            { text: vm.$t('external_research.project_duation_to_date'), bold: true, style: 'td' },
            { text: dateFormat(proposal.to_date), style: 'td' }
          ]
        ]
        pdfContent.push({
          table: {
            headerRows: 1,
            widths: ['20%', '30%', '20%', '30%'],
            body: topRows
          }
        })
        if (proposal.research_type === 3) {
        const topRows1 = [
          [
            { text: vm.$t('globalTrans.organization'), bold: true, style: 'td' },
            { text: vm.getOrgName(proposal.org_id), style: 'td' },
            { text: vm.$t('org_pro.office_type'), bold: true, style: 'td' },
            { text: vm.getOfficeType(proposal.office_type_id), style: 'td' }
          ],
          [
            { text: vm.$t('org_pro.office'), bold: true, style: 'td' },
            { text: vm.getOfficeName(proposal.office_id), style: 'td' },
            { text: vm.$t('external_research.division_department'), bold: true, style: 'td' },
            { text: vm.getDivisionName(proposal.divisional_id), style: 'td' }
          ]
        ]
        pdfContent.push({
          table: {
            headerRows: 1,
            widths: ['20%', '30%', '20%', '30%'],
            body: topRows1
          }
        })
      }
        if (proposal.research_type === 2) {
          const topRows2 = [
            [
              { text: vm.$t('external_research.lead_org_and_coordinator') + ' ' + vm.$t('sidebar.information'), bold: true, style: 'th', colSpan: '4', fillColor: '#dee2e6', alignment: 'center' },
              { },
              { },
              { }
            ],
            [
              { text: vm.$t('research_manage.organization_type'), bold: true, style: 'td' },
              { text: vm.getOrgTypeName(proposal.org_type), style: 'td' },
              { text: proposal.org_type === 3 ? vm.$t('research_manage.university_name') : vm.$t('external_research.lead_org'), bold: true, style: 'td' },
              { text: vm.getComponentOrgName(proposal.org_type, proposal.lead_org_id), style: 'td' }
            ],
            [
              { text: vm.$t('globalTrans.name'), bold: true, style: 'td' },
              { text: vm.$i18n.locale === 'en' ? proposal.coordinator_info.coord_name : proposal.coordinator_info.coord_name_bn, style: 'td' },
              { text: vm.$t('globalTrans.designation'), bold: true, style: 'td' },
              { text: vm.$i18n.locale === 'en' ? proposal.coordinator_info.coord_designation : proposal.coordinator_info.coord_designation_bn, style: 'td' }
            ],
            [
              { text: vm.$t('globalUserData.mobile_no'), bold: true, style: 'td' },
              { text: mobileNumber(proposal.coordinator_info.coord_mobile_no), style: 'td' },
              { text: vm.$t('globalTrans.email'), bold: true, style: 'td' },
              { text: proposal.coordinator_info.coord_email, style: 'td' }
            ]
          ]
          pdfContent.push({
            table: {
              headerRows: 1,
              widths: ['20%', '30%', '20%', '30%'],
              body: topRows2
            }
          })
          const allRowsHead1 = [
            [
              { text: vm.$t('external_research.component_org') + ' ' + vm.$t('sidebar.information'), style: 'th', fillColor: '#dee2e6', alignment: 'center', colSpan: '3' },
              { },
              { }
            ],
            [
              { text: vm.$t('globalTrans.sl_no'), style: 'th', alignment: 'center' },
              { text: vm.$t('research_manage.organization_type'), style: 'th', alignment: 'center' },
              { text: vm.$t('research_manage.org_or_univ_name'), style: 'th', alignment: 'center' }
            ]
          ]
          proposal.coordinator_orgs.map((item, index) => {
            allRowsHead1.push(
            [
              { text: vm.$n(index + 1), style: 'td', alignment: 'center' },
              { text: vm.getOrgTypeName(item.org_type), style: 'td', alignment: 'center' },
              { text: vm.getComponentOrgName(item.org_type, item.component_org_id), style: 'td', alignment: 'center' }
            ])
          })
          pdfContent.push({
            table: {
              headerRows: 1,
              widths: ['10%', '45%', '45%'],
              body: allRowsHead1
            }
          })
        }
        if (proposal.co_investigator) {
        const allRowsHead = [
          [
            { text: vm.$t('external_research.principal_investigator') + ' ' + vm.$t('sidebar.information'), style: 'th', fillColor: '#dee2e6', alignment: 'center', colSpan: '7' },
            { },
            { },
            { },
            { },
            { },
            { }
          ],
          [
            { text: vm.$t('globalTrans.sl_no'), style: 'th', alignment: 'center' },
            { text: vm.$t('research_manage.org_or_univ_name'), style: 'th', alignment: 'center' },
            { text: vm.$t('external_research.principal_investigator_name'), style: 'th', alignment: 'center' },
            { text: vm.$t('globalTrans.designation'), style: 'th', alignment: 'center' },
            { text: vm.$t('globalTrans.address'), style: 'th', alignment: 'center' },
            { text: vm.$t('globalUserData.mobile_no'), style: 'th', alignment: 'center' },
            { text: vm.$t('globalTrans.email'), style: 'th', alignment: 'center' }
          ]
        ]
        proposal.co_investigator.investigators.map((item, index) => {
          allRowsHead.push(
          [
            { text: vm.$n(index + 1), style: 'td', alignment: 'center' },
            { text: vm.getComponentOrgName(item.org_type, item.component_org_id), style: 'td', alignment: 'center' },
            { text: vm.$i18n.locale === 'bn' ? item.investigator_name_bn : item.investigator_name, style: 'td', alignment: 'center' },
            { text: vm.$i18n.locale === 'bn' ? item.designation_bn : item.designation, style: 'td', alignment: 'center' },
            { text: vm.$i18n.locale === 'bn' ? item.address_bn : item.address, style: 'td', alignment: 'center' },
            { text: mobileNumber(item.mobile_no), style: 'td', alignment: 'center' },
            { text: item.email, style: 'td', alignment: 'center' }
          ])
        })
        pdfContent.push({
          table: {
            headerRows: 1,
            widths: ['10%', '15%', '15%', '15%', '15%', '15%', '15%'],
            body: allRowsHead
          }
        })
      }
      if (proposal.co_investigator) {
        const topRows2 = [
          [
            { text: vm.$t('external_research.principal_co_investigator') + ' ' + vm.$t('sidebar.information'), bold: true, style: 'th', colSpan: '4', fillColor: '#dee2e6', alignment: 'center' },
            { },
            { },
            { }
          ],
          [
            { text: proposal.co_investigator.org_type === 3 ? vm.$t('research_manage.university_name') : vm.$t('external_research.component_org'), bold: true, style: 'td' },
            { text: vm.getComponentOrgName(proposal.co_investigator.org_type, proposal.co_investigator.component_org_id), style: 'td' },
            { text: vm.$t('external_research.principal_investigator_name'), bold: true, style: 'td' },
            { text: vm.$i18n.locale === 'en' ? proposal.co_investigator.co_investigator_name : proposal.co_investigator.co_investigator_name_bn, style: 'td' }
          ],
          [
            { text: vm.$t('globalTrans.designation'), bold: true, style: 'td' },
            { text: vm.$i18n.locale === 'en' ? proposal.co_investigator.designation : proposal.co_investigator.designation_bn, style: 'td' },
            { text: vm.$t('globalTrans.address'), bold: true, style: 'td' },
            { text: vm.$i18n.locale === 'en' ? proposal.co_investigator.address : proposal.co_investigator.address_bn, style: 'td' }
          ],
          [
            { text: vm.$t('globalUserData.mobile_no'), bold: true, style: 'td' },
            { text: mobileNumber(proposal.co_investigator.mobile_no), style: 'td' },
            { text: vm.$t('globalTrans.email'), bold: true, style: 'td' },
            { text: proposal.co_investigator.email, style: 'td' }
          ]
        ]
        pdfContent.push({
          table: {
            headerRows: 1,
            widths: ['20%', '30%', '20%', '30%'],
            body: topRows2
          }
        })
      }
      if (proposal.description) {
        const topRows3 = [
          [
            { text: vm.$t('external_research.project_description'), bold: true, style: 'th', colSpan: '4', fillColor: '#dee2e6', alignment: 'center' },
            { },
            { },
            { }
          ],
          [
            { text: vm.$t('external_research.background'), bold: true, style: 'td' },
            { text: vm.$i18n.locale === 'en' ? proposal.description.background : proposal.description.background_bn, style: 'td' },
            { text: vm.$t('external_research.goal'), bold: true, style: 'td' },
            { text: vm.$i18n.locale === 'en' ? proposal.description.goal : proposal.description.goal_bn, style: 'td' }
          ],
          [
            { text: vm.$t('external_research.general_objectives'), bold: true, style: 'td' },
            { text: vm.$i18n.locale === 'en' ? proposal.description.general_objective : proposal.description.general_objective_bn, style: 'td' },
            { text: vm.$t('external_research.targeted_beneficiaries'), bold: true, style: 'td' },
            { text: vm.$i18n.locale === 'en' ? proposal.description.targeted_beneficiaries : proposal.description.targeted_beneficiaries_bn, style: 'td' }
          ],
          [
            { text: vm.$t('external_research.literature_review'), bold: true, style: 'td' },
            { text: vm.$i18n.locale === 'en' ? proposal.description.literature_review : proposal.description.literature_review_bn, style: 'td', colSpan: '3' },
            { },
            { }
          ],
          [
            { text: vm.$t('external_research.bench_mark_info'), bold: true, style: 'td' },
            { text: vm.$i18n.locale === 'en' ? proposal.description.targeted_beneficiaries : proposal.description.targeted_beneficiaries_bn, style: 'td', colSpan: '3' },
            { },
            { }
          ]
        ]
        pdfContent.push({
          table: {
            headerRows: 1,
            widths: ['20%', '30%', '20%', '30%'],
            body: topRows3
          }
        })
        const allRowsHead8 = [
          [
            { text: vm.$t('external_research.specific_objectives'), style: 'th', fillColor: '#dee2e6', alignment: 'center', colSpan: '2' },
            { }
          ]
        ]
        proposal.description.details.map((item, index) => {
          allRowsHead8.push(
          [
            { text: vm.$n(index + 1), style: 'td', alignment: 'center' },
            { text: vm.$i18n.locale === 'bn' ? item.specific_objective_bn : item.specific_objective, style: 'td', alignment: 'center' }
          ])
        })
        pdfContent.push({
          table: {
            headerRows: 1,
            widths: ['20%', '80%'],
            body: allRowsHead8
          }
        })
      }
      if (proposal.locations.length > 0) {
        const allRowsHead2 = [
          [
            { text: vm.$t('external_research.implementation_location') + ' ' + vm.$t('sidebar.information'), style: 'th', fillColor: '#dee2e6', alignment: 'center', colSpan: '3' },
            // { },
            { },
            { }
          ],
          [
            { text: vm.$t('globalTrans.sl_no'), style: 'th', alignment: 'center' },
            // { text: vm.$t('globalTrans.org_name'), style: 'th', alignment: 'center' },
            { text: vm.$t('external_research.division_section'), style: 'th', alignment: 'center' },
            { text: vm.$t('globalTrans.address'), style: 'th', alignment: 'center' }
          ]
        ]
        proposal.locations.map((item, index) => {
          allRowsHead2.push(
          [
            { text: vm.$n(index + 1), style: 'td', alignment: 'center' },
            // { text: vm.$i18n.locale === 'bn' ? item.org_name_bn : item.org_name, style: 'td', alignment: 'center' },
            { text: vm.$i18n.locale === 'bn' ? item.division_bn : item.division, style: 'td', alignment: 'center' },
            { text: vm.$i18n.locale === 'bn' ? item.address_bn : item.address, style: 'td', alignment: 'center' }
          ])
        })
        pdfContent.push({
          table: {
            headerRows: 1,
            widths: ['10%', '*', '*'],
            body: allRowsHead2
          }
        })
      }
      if (proposal.research_type === 2) {
        pdfContent.push(
          { text: '', pageOrientation: 'portrait', pageBreak: 'after' }
        )
      }
      if (proposal.activities.length > 0) {
        const allRowsHead3 = [
          [
            { text: vm.$t('external_research.activity_list') + ' ' + vm.$t('sidebar.information'), style: 'th', fillColor: '#dee2e6', alignment: 'center', colSpan: '10' },
            { },
            { },
            { },
            { },
            { },
            { },
            { },
            { },
            { }
          ],
          [
            { text: vm.$t('globalTrans.sl_no'), style: 'th', alignment: 'center' },
            { text: vm.$t('external_research.specific_objectives'), style: 'th', alignment: 'center' },
            { text: vm.$t('external_research.activity_name'), style: 'th', alignment: 'center' },
            { text: vm.$t('globalTrans.start_date'), style: 'th', alignment: 'center' },
            { text: vm.$t('globalTrans.end_date'), style: 'th', alignment: 'center' },
            { text: vm.$t('external_research.monitoring_indigator'), style: 'th', alignment: 'center' },
            { text: vm.$t('external_research.expected_output'), style: 'th', alignment: 'center' },
            { text: vm.$t('external_research.baseline_year'), style: 'th', alignment: 'center' },
            { text: vm.$t('external_research.baseline_value'), style: 'th', alignment: 'center' },
            { text: vm.$t('globalTrans.description'), style: 'th', alignment: 'center' }
          ]
        ]
        proposal.activities.map((item, index) => {
          allRowsHead3.push(
          [
            { text: vm.$n(index + 1), style: 'td', alignment: 'center' },
            { text: item.specific_obj_id ? vm.getObjectiveName(item.specific_obj_id) : '', style: 'td', alignment: 'center' },
            { text: vm.$i18n.locale === 'bn' ? item.activity_name_bn : item.activity_name, style: 'td', alignment: 'center' },
            { text: dateFormat(item.start_date), style: 'td', alignment: 'center' },
            { text: dateFormat(item.end_date), style: 'td', alignment: 'center' },
            { text: item.monitoring_indigator, style: 'td', alignment: 'center' },
            { text: item.expected_output, style: 'td', alignment: 'center' },
            { text: vm.$n(item.baseline_year, { useGrouping: false }), style: 'td', alignment: 'center' },
            { text: item.baseline_value, style: 'td', alignment: 'center' },
            { text: vm.$i18n.locale === 'bn' ? item.description_bn : item.description, style: 'td', alignment: 'center' }
          ])
        })
        pdfContent.push({
          table: {
            headerRows: 1,
            widths: ['5%', '15%', '12%', '9%', '9%', '10%', '10%', '9%', '10%', '10%'],
            body: allRowsHead3
          }
        })
      }
      if (proposal.research_type === 1) {
        pdfContent.push(
          { text: '', pageOrientation: 'portrait', pageBreak: 'after' }
        )
      }
      if (proposal.methodology) {
        const topRows4 = [
          [
            { text: vm.$t('external_research.research_methodology') + ' ' + vm.$t('globalTrans.and') + ' ' + vm.$t('external_research.expected_output_outcome'), bold: true, style: 'th', colSpan: '4', fillColor: '#dee2e6', alignment: 'center' },
            { },
            { },
            { }
          ],
          [
            { text: vm.$t('external_research.approach_and_methodology'), bold: true, style: 'td' },
            { text: vm.$i18n.locale === 'bn' ? proposal.methodology.approach_and_methodology_bn : proposal.methodology.approach_and_methodology, style: 'td', colSpan: '3' },
            { },
            { }
          ],
          [
            { text: vm.$t('external_research.output'), bold: true, style: 'td' },
            { text: vm.$i18n.locale === 'bn' ? proposal.methodology.output_bn : proposal.methodology.output, style: 'td', colSpan: '3' },
            { },
            { }
          ],
          [
            { text: vm.$t('external_research.outcome'), bold: true, style: 'td' },
            { text: vm.$i18n.locale === 'bn' ? proposal.methodology.outcome_bn : proposal.methodology.outcome, style: 'td', colSpan: '3' },
            { },
            { }
          ]
        ]
        pdfContent.push({
          table: {
            headerRows: 1,
            widths: ['20%', '30%', '20%', '30%'],
            body: topRows4
          }
        })
      }
      if (proposal.budget) {
        const allRowsHead5 = [
          [
            { text: vm.$t('external_research.budget_info'), style: 'th', fillColor: '#dee2e6', alignment: 'center', colSpan: '7' },
            { },
            { },
            { },
            { },
            { },
            { }
          ],
          [
            { text: vm.$t('globalTrans.sl_no'), style: 'th', alignment: 'center' },
            { text: vm.$t('research_manage.budget_type'), style: 'th', alignment: 'center' },
            { text: vm.$t('external_research.budget_head'), style: 'th', alignment: 'center' },
            { text: vm.$t('globalTrans.code'), style: 'th', alignment: 'center' },
            { text: vm.$t('external_research.expense_year'), style: 'th', alignment: 'center' },
            { text: vm.$t('external_research.budget_percentage'), style: 'th', alignment: 'center' },
            { text: vm.$t('external_research.allocation_amount') + ' (' + vm.$t('globalTrans.taka') + ')', style: 'th', alignment: 'center' }
          ]
        ]
        proposal.budget.details.map((item, index) => {
          allRowsHead5.push(
          [
            { text: vm.$n(index + 1), style: 'td', alignment: 'center' },
            { text: vm.getBudgetTypeName(item.budget_type_id), style: 'td', alignment: 'center' },
            { text: vm.getBudgetName(item), style: 'td', alignment: 'center' },
            { text: vm.$n(item.code, { useGrouping: false }), style: 'td', alignment: 'center' },
            { text: vm.getExpenseYearName(item.expense_year), style: 'td', alignment: 'center' },
            { text: vm.$n(item.budget_percentage), style: 'td', alignment: 'center' },
            { text: Number.isInteger(parseFloat(item.allocation_amount)) ? (vm.$n(item.allocation_amount) + (i18n.locale === 'bn' ? '.০০' : '.00')) : vm.$n(item.allocation_amount), style: 'td', alignment: 'center' }
          ])
        })

        pdfContent.push({
          table: {
            headerRows: 1,
            widths: ['10%', '*', '*', '*', '*', '*', '*'],
            body: allRowsHead5
          }
        })
        pdfContent.push({
          table: {
            headerRows: 1,
            widths: ['10%', '*', '*', '*', '*', '*', '*'],
            body: [
              [
                { text: vm.$t('external_research.target_budget') + '' + vm.$t('globalTrans.taka') + ' = ' + vm.$n(proposal.budget.target_budget), style: 'th', alignment: 'middle', colSpan: 3 },
                { },
                { },
                { text: vm.$t('external_research.total_budget') + '' + vm.$t('globalTrans.taka') + '\n' + vm.$t('external_research.total_budget') + ' (%)', style: 'td', alignment: 'right', colSpan: 3 },
                { },
                { },
                { text: (Number.isInteger(parseFloat(proposal.budget.total_budget)) ? (vm.$n(proposal.budget.total_budget) + (i18n.locale === 'bn' ? '.০০' : '.00')) : vm.$n(proposal.budget.total_budget)) + '\n' + vm.$n(proposal.budget.total_budget_perc), style: 'td', alignment: 'center' }
              ]
            ]
          }
        })
      }
        pdfContent.push({ text: '', style: 'fertilizer' })
        var docDefinition = {
        content: pdfContent,
        pageSize: 'A4',
        pageOrientation: 'portrait',
        styles: {
          th: {
            fontSize: (i18n.locale === 'bn') ? 10 : 9,
            bold: true,
            margin: [3, 3, 3, 3]
          },
          td: {
            fontSize: (i18n.locale === 'bn') ? 9 : 8,
            margin: [3, 3, 3, 3]
          },
          fertilizer: {
            margin: [5, 0, 0, 5]
          },
          header: {
              fontSize: 18,
              bold: true,
              margin: [0, -10, 0, -20]
          },
          org: {
              fontSize: 13,
              bold: true,
              margin: [0, -25, 10, 5]
          },
          address: {
              fontSize: 11,
              margin: [0, 0, 0, 5]
          },
          hh: {
              fontSize: 13,
              bold: true,
              margin: [10, 10, 25, 20]
          },
          header2: {
            fontSize: 14,
            margin: [0, 10, 0, 20]
          },
          headerPort1: {
            fontSize: 10,
            margin: [0, 20, 0, 5]
          },
          headerPort: {
            fontSize: 10,
            margin: [0, 4, 0, 15]
          },
          krishi: {
            margin: [0, -5, 0, 15],
            alignment: 'center'
          },
          header3: {
            fontSize: 9,
            margin: [0, 0, 0, 0]
          },
          tableSubHead: {
            margin: [0, 5, 0, 15]
          }
        }
      }
      pdfMake.createPdf(docDefinition, null, null, null).print()
      // pdfMake.createPdf(docDefinition, null, null, null).download('research-project-report')
    } catch (error) {
      if (error) {}
    }
    Store.commit('mutateCommonProperties', {
      loading: false
    })
}
export default {
  exportPdfDetails
}
