<template>
  <b-container fluid>
    <b-row>
      <b-col md="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">{{ $t('research_manage.proposal_technocal_evaluation') }}</h4>
          </template>
          <template v-slot:body>
            <b-overlay :show="loadingState">
              <b-row>
                <b-col md="12" class="table-responsive">
                  <b-table thead-class="bg-primary" bordered hover :items="listData" :fields="columns" aria-hidden="loading | listReload ? 'true' : null">
                    <template v-slot:cell(index)="data">
                      {{ $n(data.index + 1) }}
                    </template>
                    <template v-slot:cell(created_at)="data">
                        {{ data.item.created_at | dateFormat }}
                    </template>
                    <template v-slot:cell(proposal_status)="data">
                        <span class="badge badge-primary" v-if="data.item.proposal_status === 0">{{$t('globalTrans.new')}}</span>
                        <span class="badge badge-info" v-if="data.item.proposal_status === 1">{{$t('globalTrans.return')}}</span>
                        <span class="badge badge-success" v-if="data.item.proposal_status === 2">{{$t('globalTrans.approve')}}</span>
                        <span class="badge badge-danger" v-if="data.item.proposal_status === 3">{{$t('globalTrans.reject')}}</span>
                        <span class="badge badge-warning" v-if="data.item.proposal_status == 4">{{ $t('globalTrans.re_submitted') }}</span>
                        <!-- <span class="badge badge-danger" v-if="data.item.is_recommended === 1">{{$t('globalTrans.recommend')}}</span> -->
                    </template>
                    <template v-slot:cell(action)="data">
                        <b-button v-if="data.item.proposal_status === 0 || data.item.proposal_status == 4" v-b-modal.modal-6 variant="iq-bg-primary" class="btn btn-primary m-2" size="sm" title="Return" @click="note(data.item)"><i class="fas fa-undo"></i></b-button>
                        <b-button v-if="data.item.proposal_status === 0 || data.item.proposal_status == 4" v-b-modal.modal-5 variant="iq-bg-success" class="btn btn-success m-2" size="sm" title="Recommend" @click="note(data.item)"><i class="fas fa-thumbs-up"></i></b-button>
                        <b-button v-if="data.item.proposal_status === 0 || data.item.proposal_status == 4" v-b-modal.modal-4 variant="iq-bg-danger" class="btn btn-danger m-2" size="sm" title="Reject" @click="note(data.item)"><i class="fa fa-ban" aria-hidden="true"></i></b-button>
                        <b-button title="View Details" v-b-modal.modal-9 variant=" iq-bg-success border-info mr-1" size="sm" @click="view(data.item)"><i class="ri-eye-line m-2"></i></b-button>
                    </template>
                  </b-table>
                </b-col>
              </b-row>
            </b-overlay>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <b-modal id="modal-4" size="lg" :title="$t('research_manage.review_remark')" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
        <p>
            <RejectV :id="itemId" :key="itemId"/>
        </p>
    </b-modal>
    <b-modal id="modal-5" size="lg" :title="$t('research_manage.review_remark')" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
        <p>
            <RecommendV :id="itemId" :key="itemId"/>
        </p>
    </b-modal>
    <b-modal id="modal-6" size="lg" :title="$t('research_manage.review_remark')" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
        <p>
            <ReturnV :id="itemId" :key="itemId"/>
        </p>
    </b-modal>
    <b-modal id="modal-9" size="xl" :title="this.$t('external_sidebar.research_proposal_submission_details')" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
      <Details :id="application" :key="application" />
    </b-modal>
  </b-container>
</template>
<script>
import RejectV from './Reject'
import RecommendV from './Recommend'
import ReturnV from './Return'
import RestApi, { agriResearchServiceBaseUrl } from '@/config/api_config'
import { proposalTechnicalEvaluationList } from '../../api/routes'
import Details from '../proposal-approval/Details'
import ModalBaseMasterList from '@/mixins/modal-base-master-list'
import { mapGetters } from 'vuex'

export default {
  mixins: [ModalBaseMasterList],
  components: {
      RejectV, RecommendV, ReturnV, Details
  },
  data () {
    return {
      baseUrl: agriResearchServiceBaseUrl,
      testId: 0,
      rows: [],
      itemId: 0,
      application: 0,
      item: ''
    }
  },
  watch: {
    loadingState: function (newVal, oldVal) {
      if (newVal) {
        this.loadData()
      }
    }
  },
  computed: {
    ...mapGetters({
      authUser: 'Auth/authUser'
    }),
    thematicAreaList: function () {
        return this.$store.state.AgriResearch.commonObj.thematicAreaList.filter(item => item.status === 1)
    },
    formTitle () {
       return (this.editItemId === 0) ? this.$t('research_manage.proposal_invitation') + ' ' + this.$t('globalTrans.entry') : this.$t('research_manage.proposal_invitation') + ' ' + this.$t('globalTrans.update')
    },
    currentLocale () {
      return this.$i18n.locale
    },
    columns () {
      const labels = [
          { label: this.$t('globalTrans.sl_no'), class: 'text-left' },
          { label: this.$t('research_manage.thematic_area'), class: 'text-left' },
          { label: this.$t('research_manage.proposal_id'), class: 'text-left' },
          { label: this.$t('research_manage.project_title'), class: 'text-left' },
          { label: this.$t('research_manage.submission_date'), class: 'text-left' },
          { label: this.$t('globalTrans.status'), class: 'text-center' },
          { label: this.$t('globalTrans.action'), class: 'text-center' }
        ]

      let keys = []

      if (this.$i18n.locale === 'bn') {
        keys = [
          { key: 'index' },
          { key: 'thematic_name_bn' },
          { key: 'proposal_auto_id' },
          { key: 'project_title_bn' },
          { key: 'created_at' },
          { key: 'proposal_status' },
          { key: 'action' }
        ]
      } else {
        keys = [
          { key: 'index' },
          { key: 'thematic_name' },
          { key: 'proposal_auto_id' },
          { key: 'project_title' },
          { key: 'created_at' },
          { key: 'proposal_status' },
          { key: 'action' }
        ]
      }

      return labels.map((item, index) => {
        return Object.assign(item, keys[index])
      })
    }
  },
  mounted () {
    this.loadData()
    // flatpickr('.fromDate', {})
  },
  methods: {
    details (item) {
      this.item = item
    },
    view (item) {
      this.application = item.id
    },
    note (item) {
      this.itemId = item.id
    },
    loadData () {
        this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
        RestApi.getData(agriResearchServiceBaseUrl, proposalTechnicalEvaluationList).then(response => {
          if (response.success) {
            this.$store.dispatch('setList', this.dataList(response.data))
            this.paginationData(response.data)
          }
          this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
        })
    },
    dataList (data) {
      const listData = data.map(item => {
        const thematicAreaObj = this.$store.state.AgriResearch.commonObj.thematicAreaList.find(Item => Item.value === item.thematic_area_id)
          const thematicAreaData = {}
          if (typeof thematicAreaObj !== 'undefined') {
            thematicAreaData.thematic_name = thematicAreaObj.text_en
            thematicAreaData.thematic_name_bn = thematicAreaObj.text_bn
          } else {
            thematicAreaData.thematic_name = ''
            thematicAreaData.thematic_name_bn = ''
          }
        return Object.assign({}, item, thematicAreaData)
      })
      return listData
    }
  }
}
</script>
